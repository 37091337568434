/* eslint-disable no-console,no-undef,camelcase */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isNull from 'lodash/isNull';
import isUndefined from 'lodash/isUndefined';

import { FeaturedEventsContainer, StyledSvg, TitleContent, NoData } from './styles';

import SingleFeaturedEvent from './SingleFeaturedEvent';
import Waiting from '../Waiting';

import { getFeaturedEvents, getFeaturedEventsAPI, attendEvent } from '../../redux/actions';

import {withTranslation} from 'react-i18next';

class FeaturedEvents extends React.Component{

  componentDidMount() {
    const { getFeaturedEventsAPI, featuredEventData } = this.props;
    if(!featuredEventData) getFeaturedEventsAPI();
  }

  render() {
    const { featuredEventData, attendEvent, history, t } = this.props;
    if(isUndefined(featuredEventData) || isNull(featuredEventData)) {
      return <Waiting />
    }
    return(
      <FeaturedEventsContainer>
        <TitleContent feature={1} isSocialFeedsPage>
          <StyledSvg feature={1} viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.1 0.9L14.4 0.9 14.4 0 3.6 0 3.6 0.9 0.9 0.9C0.4 0.9 0 1.3 0 1.8L0 4C0 6 1.5 7.7 3.6 8L3.6 8.1C3.6
                  10.7 5.4 12.9 7.8 13.4L7.2 15.3 5.1 15.3C4.8 15.3 4.4 15.6 4.3 15.9L3.6 18 14.4 18 13.7 15.9C13.6 15.6 13.2 15.3 12.9
                  15.3L10.8 15.3 10.2 13.4C12.6 12.9 14.4 10.7 14.4 8.1L14.4 8C16.5 7.7 18 6 18 4L18 1.8C18 1.3 17.6 0.9 17.1 0.9ZM3.6 6.2C2.6
                  5.9 1.8 5 1.8 4L1.8 2.7 3.6 2.7 3.6 6.2ZM10.8 9L9 8 7.2 9 7.7 7.2 6.3 5.4 8.2 5.4 9 3.6 9.8 5.4 11.7 5.4 10.4 7.2 10.8 9ZM16.2
                  4C16.2 5 15.4 6 14.4 6.2L14.4 2.7 16.2 2.7 16.2 4Z"
            />
          </StyledSvg>
          <span>{t("Featured Events")}</span>
        </TitleContent>
        <div>
          {
            featuredEventData.length ? featuredEventData.map((event, index) =>
              <SingleFeaturedEvent
                key={index}
                event={event}
                attendEvent={attendEvent}
                history={history}
              />
            ) :
              <NoData>
                {t("No Featured Event")}
              </NoData>
          }
        </div>        
      </FeaturedEventsContainer>
    );
  }
} 

FeaturedEvents.propTypes = {
  fetchFeaturedEvent: PropTypes.func.isRequired,
  featuredEventData: PropTypes.array,
  getFeaturedEventsAPI: PropTypes.func.isRequired,
  attendEvent: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  featuredEventData: state.events.featureEvents
});

const mapDispatchToProps = (dispatch) => ({
  fetchFeaturedEvent: () => dispatch(getFeaturedEvents()),
  getFeaturedEventsAPI: () => dispatch(getFeaturedEventsAPI()),
  attendEvent: (eventId, isAttending, updationList) => dispatch(attendEvent(eventId, isAttending, updationList)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(FeaturedEvents));
