/* eslint-disable no-console,no-undef,camelcase */
import React, { Component } from 'react';
import moment from "moment";
import PropTypes from 'prop-types';

import { EventContainer, ImageContainer, EventContent, EventDate, EventJoiningTime, SaveButton } from './styles';

import { DateIcon } from '../SvgComponents';
import ImageThumbnail from '../ImageThumbnail';

import { ImageUrl } from '../../utils/constants';
import { getTimeToShow, convertDateInTimezone } from '../../utils/methods';
import { withTranslation } from 'react-i18next';

class SingleFeaturedEvent extends Component {
  constructor() {
    super();
    this.state = {
      time: []
    }
  }

  componentDidMount() {
    const { event } = this.props;
    this.interval = setInterval(() => {
      this.setState({
        time: getTimeToShow(event, 'event')
      })
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    const { event, attendEvent, history, t} = this.props;
    const { time } = this.state;
    let eventId = event.id;
    return (
      <EventContainer onClick={() => history.push(`/events/${eventId}`)}>
        <div style={{width: '100%', float: 'left', position: 'relative'}}>
          <ImageContainer>
            <ImageThumbnail src={`${ImageUrl}/${event.image}`} alt={event.title} />
          </ImageContainer>
          <EventContent>
            <span>{t(event.title)}</span>
            <EventDate>
              <div>
                <span>
                  <DateIcon color="black" viewBox="0 0 16 16" />
                </span>
                <span>{t("Start Date")}: </span>
                <span>{moment(convertDateInTimezone(event.start_date)._d).format('MMM DD, YYYY hh:mm a')}</span>
              </div>
              <div>
                <span>
                  <DateIcon color="black" viewBox="0 0 16 16" />
                </span>
                <span>{t("End Date")}: </span>
                <span>{moment(convertDateInTimezone(event.end_date)._d).format('MMM DD, YYYY hh:mm a')}</span>
              </div>
            </EventDate>
          </EventContent>
        </div>
        <EventJoiningTime>
          {
            (event.event_status === 'over' || convertDateInTimezone(event.end_date)._d <= moment()._d) ? <div><span>{t("Event has ended")}</span></div> :
              convertDateInTimezone(event.start_date)._d <= moment()._d ? <div><span>{t("Event is in progress")}</span></div> :
                <div>
                  <span>
                    {time.map((step, index) => step + (index === (time.length - 1) ? '' : ', '))}
                  </span> {t("until this event will start")}.
                </div>
          }
        </EventJoiningTime>
        <SaveButton
          color="#ef6827"
          disabled={event.event_status === 'over' || convertDateInTimezone(event.end_date)._d <= moment()._d}
          onClick={() => attendEvent(event.id, event.is_attending, 'featureEvents')}
          rsvp
        >
          {event.is_attending === 1 ? t('attending') : t('rsvp')}
        </SaveButton>
      </EventContainer>
    )
  }
}

SingleFeaturedEvent.propTypes = {
  attendEvent: PropTypes.func.isRequired,
  event: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  t: PropTypes.func
};

export default (withTranslation()(SingleFeaturedEvent));
