import styled, { css } from 'styled-components';

const RegularFont = 'Rubik-Regular';
const BoldFont = 'Rubik-Bold';

const TitleContent = styled.div`
  font-size: 14px;
  font-family: ${({border}) => border && 'Rubik-Medium'};
  border-bottom: ${({ border }) => border ? 'none' : '1px solid #e2e2e4'};
  text-transform: ${({ border }) => border && 'uppercase'};
  width: 100%;
  float: left;
  padding: ${({ feature }) => feature ? '0px' : '0 15px 10px 5px'};
  display: ${({ feature }) => feature && 'flex' };
  align-items: ${({ feature }) => feature && 'center' };

  > svg {
    margin-top: ${({ feature }) => !feature && '4px' };
    padding-bottom: ${({ feature }) => feature && '5px' };
  }

  > img {
    width: 20px;
    height: 20px;
    float: left;
  }

  > span {
    padding-bottom: ${({ feature }) => feature && '5px' };
    opacity: ${({ feature }) => feature && '0.7' };
    letter-spacing: ${({ feature }) => feature && '1px' };
    line-height: ${({ feature }) => feature ? '15px' : '29px'};
    color: ${({ feature }) => feature && '#26262B' };
    > span {
      color: #159fc9;
    }
  }
`;

const FeaturedEventsContainer = styled.div`
  width: 100%;
  background-color: ${({bgColor}) => bgColor ? 'transparent' : 'white'};
  float: left;
  padding:  ${({bgColor}) => bgColor ? '0px' : '15px 18px'};
  box-shadow: ${({bgColor}) => bgColor ? 'none' : '1px 1.732px 46px rgba(0,0,0,0.18)'};
  font-family: ${RegularFont};
`;

const StyledSvg = styled.svg`
  height: 20px;
  width: 25px;
  float: left;
  fill: #159fc9;
  padding-top: ${({ feature }) => !feature && '4px'};
  padding-bottom: ${({ feature }) => feature && '4px' };
  margin-top: ${(props) => props.isSocialFeedsPage && '4px'};
`;

const ImageContainer = styled.div`
  width: 40%;
  float: left;
  display: inline-block;

  @media (max-width: 1200px) and (min-width: 768px) {
    width: 100%;
  }
  
  > img {
    width: 100%;
  }
`;

const EventContainer = styled.div`
  width: 100%;
  float: left;
  cursor: pointer;
  padding: ${(props) => props.isSocialFeedsPage ? '20px 10px 15px 10px' : '19px 0 17px 0'};
  border-bottom: 1px solid #dededf;

  &:last-child {
    border-bottom: none;
  }
`;

const SaveButton = styled.button`
  background-color: ${(props) => props.color || '#ef6827'};
  border: ${(props) => `1px solid ${props.color}` || '1px solid #ef6827'};
  color: white;
  white-space: nowrap;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 30px;
  text-transform: uppercase;
  margin-top: ${(props) => props.isSocialFeedsPage ? '' : '10px'};
  margin-bottom: ${(props) => props.addMargin ? '40px' : '0'};
  float: ${(props) => props.isSocialFeedsPage ? 'right' : 'left'};
  font-family: ${BoldFont};
  width: 76px;
  height: 33px;

  &:focus, &:active, &:hover {
    outline: none;
  }
  
  > i {
    margin-right: 5px;
  }
  ${({ disabled }) => disabled && css`
    opacity: 0.5;
    cursor: not-allowed !important;
  `}

  ${({ rsvp }) => rsvp && css`
    padding: 7px 15px;
    width: auto;
    height: auto;
  `}
`;

const EventJoiningTime = styled.div`
  float: left;
  width: 100%;
  margin-top: 10px;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 24px;
  
  span {
    color: rgb(239,104,39);
    font-family: ${BoldFont};
  }
`;

const EventContent = styled.div`
  float: left;
  width: 60%;
  padding-left: 10px;
  display: inline-block;

  @media (max-width: 1200px) and (min-width: 768px) {
    width: 100%;
    padding-left: 0;
    margin-top: 15px;
  }
  
  > span {
    font-size: 16px;
    letter-spacing: 0px;
    color: rgb(51,51,51);
    width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    max-width: 100%;
    text-overflow: ellipsis;
  }
`;

const EventDate = styled.div`
  float: left;
  font-size: 12px;
  letter-spacing: 0px;
  line-height: 20px;
  margin-top: 10px;
  
  svg {
    height: 11px;
    width: 11px;
    margin-top: 4px;
    margin-right: 4px;
    float: left;
  }
  
  span:last-child {
    color: #159fc9;
    font-family: ${BoldFont};
  }
`;

const NoData = styled.div`
  padding: 23px 0 12px 0;
  display: inline-block;
  width: 100%;
  margin-left: 8px
`;

export { FeaturedEventsContainer, StyledSvg, EventContainer, ImageContainer, EventContent, EventDate, EventJoiningTime, SaveButton, TitleContent, NoData };
